import React, { useEffect } from "react"
import { useTrackingCode } from "react-hubspot-tracking-code-hook"
import { useQuery } from "react-query"
import { useLocation } from "react-router-dom"
import { useAuthStore } from "../context/AuthContext"
import { getUserProfile } from "../screens/account/AccountClient"

export const HubspotTracker: React.FC = () => {
  const { setPathPageView, setIdentity } = useTrackingCode()
  const userName = useAuthStore((state) => state.currentUser?.UserName)
  const { data: userProfileData } = useQuery(["user-profile-static", userName], () => getUserProfile(), {
    enabled: !!userName,
    staleTime: Infinity,
  })
  const location = useLocation()

  useEffect(() => {
    if (userProfileData) {
      setIdentity(userProfileData.emailAddress)
    }
  }, [userProfileData, setIdentity])

  useEffect(() => {
    if (userProfileData) {
      setPathPageView(location.pathname)
    }
  }, [location.pathname, setPathPageView])

  return null
}
